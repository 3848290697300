import { Text } from "@chakra-ui/core";
import React from "react";

const Price = ({ price, payPlan, customPrice, ...props }) => {
  return (
    <Text
      maxW="full"
      fontWeight="bold"
      fontSize="4xl"
      whiteSpace="nowrap"
      {...props}
    >
      {!customPrice ? price : price.value}
      {!customPrice && (
        <Text fontWeight="light" ml={2} as="span" fontSize="sm">
          {payPlan === "month" && "/ użyt./mies."}
          {payPlan === "year" && "/ użyt./mies."}
          {payPlan === "onetime" && "/ konto"}
        </Text>
      )}
    </Text>
  );
};

export default Price;
