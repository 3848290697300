import React, { Component } from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Container from "../components/container";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaQuestion,
  FaServer,
  FaLaptop,
  FaArrowUp,
  FaCubes,
  FaClock,
  FaCogs,
  FaComments,
  FaPlus,
} 
from "react-icons/fa";
import {
  FcAssistant,
  FcBiotech,
  FcBriefcase,
  FcCellPhone,
  FcCollaboration,
  FcDatabase,
  FcInternal,
  FcInvite,
  FcProcess,
  FcPuzzle,
  FcReading,
  FcUnlock,
} from "react-icons/fc";
import styles from "./crm-cennik.module.css";
import Qa from "../components/qa";
import { Box, Link, Text } from "@chakra-ui/core";
import { graphql, Link as GatsbyLink } from "gatsby";
import ReactTooltip from "react-tooltip";
import SEO from "../components/seo";
import TryButton from "../components/TryButton";
import FeatureList from "../components/Pricing/FeatureList";
import FeatureListItem from "../components/Pricing/FeatureListItem";
import Pricing from "../components/Pricing/Pricing";
import PricingButton from "../components/Pricing/PricingButton";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";
import Tile from "../components/Tile";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "synergius-logo1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "sp-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: { eq: "automico-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "logo-aura.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `;




const Cennik = ({ data }) => {
    return (
      <Layout>
        <SEO
          title="CRM cennik. Dowiedz się, ile kosztuje prosty CRM"
          description="CRM cennik • Prosty CRM dla dynamicznych zespołów • Szanse sprzedaży, kartoteki kontrahentów, kalendarz i zadania, raporty, uprawnienia • QuickCRM"
        />
        <Hero maxHeight="280px" justifyContent="center">
          <h1>Ile kosztuje prosty CRM?</h1>
          <p>Sprawdź ofertę na plan abonamentowy</p>
        </Hero>
<Container>
        <br />
        <Pricing
          specialColors={[
            "hsl(210, 47%, 69%)",
            " hsl(210, 68%, 57%)",
            "  hsl(218, 17%, 35%)",
          ]}
          allFunctions={allFunctions}
          pricingData={pricingData}
          showFeaturesTables
        ></Pricing>
      </Container>
      <Container>
      <section
      style={{
        backgroundColor: "rgba(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container">
        <div
          style={{
            flex: 2
          }}
          className="container__text"
        >
       
          <div className={styles.textWrapper}>
            <center><h3>Co wyróżnia QuickCRM?</h3></center>
            <br />
          </div>
          <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaServer size="45px" />}
          content="Utrzymanie systemu na naszych&nbsp;serwerach"
        />
        <BenefitBlock
          icon={<FaLaptop size="45px" />}
          content="Błyskawiczny dostęp — bez potrzeby skomplikowanego wdrażania"
        />
        <BenefitBlock
          icon={<FaArrowUp  size="45px" />}
          content="Możliwość przejścia na wyższe rozwiązanie bez kosztów migracji danych"
        />
        <BenefitBlock
          icon={<FaCubes size="45px" />}
          content="Bezpłatne aktualizacje systemu zawierające nowe moduły oraz usprawnienia"
        />
      </BenefitBlockWrapper>
       </div>
       </div>
    </section>
    </Container>

            <Container>

          <center><h3 style= {{paddingBottom:20,
            paddingTop:20
          }}>9 korzyści dla Ciebie i&nbsp;Twojej firmy
</h3></center>


        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcUnlock size="70px" />
            <br />
            <h3>Brak limitów klientów, szans sprzedaży, zleceń</h3>
            <p>
              Nie martwisz się
              <br />o&nbsp;ograniczenia systemu.
            </p>
          </Tile>
          <Tile>
            <FcReading size="70px" />
            <br />
            <h3>Bezpłatne materiały how–to i&nbsp;wiedza biznesowa.</h3>
            <p>
              Zyskujesz przewagę
              <br />
              konkurencyjną.
            </p>
          </Tile>

          <Tile>
            <FcPuzzle size="70px" color="#999999" />
            <br />
            <h3>
              Dostępność komplementarnych systemów dla innych obszarów firmy
            </h3>
            <p>Dowolnie rozwijasz funkcjonalność systemu.</p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcBiotech size="70px" />
            <br />
            <h3>Możliwość wprowadzania indywidualnych zmian</h3>
            <p>Cieszysz się wygodą użytkowania systemu.</p>
          </Tile>
          <Tile>
            <FcInternal size="70px" />
            <br />
            <h3>Bezpłatne i&nbsp;automatyczne aktualizacje</h3>
            <p>Używasz najnowszego i&nbsp;bezpiecznego systemu.</p>
          </Tile>

          <Tile>
            <FcBriefcase size="70px" color="#999999" />
            <br />
            <h3>
              Bezpłatne doradztwo
              <br />
              biznesowe
            </h3>
            <p>Podpowiadamy, jak możesz rozwinąć firmę.</p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcCollaboration size="70px" />
            <br />
            <h3>Dostęp do dedykowanego panelu klienta</h3>
            <p>Masz stały dostęp do statusu zgłoszenia.</p>
          </Tile>
          <Tile>
            <FcAssistant size="70px" />
            <br />
            <h3>
              Obsługa i&nbsp;wsparcie
              <br />
              w&nbsp;j. polskim
            </h3>
            <p>Bez problemu załatwisz z&nbsp;nami wszystkie sprawy.</p>
          </Tile>

          <Tile>
            <FcCellPhone size="70px" color="#999999" />
            <br />
            <h3>Dział wsparcia do dyspozycji</h3>
            <p>Możesz zadzwonić, gdy potrzebujesz pomocy.</p>
          </Tile>
        </div>
      </Container>
      <Container>
          <center><h3>Poznaj nasze inne rozwiązania</h3></center>
          </Container>
          <Container className="container container--text"> 
<div className="text-answers">
            <div className="text-block">
            <a href="https://synergiuscrm.pl/">
          <Img
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 300
          }}
          fluid={data.zdjecie1.childImageSharp.fluid}
          alt="Synergius CRM"
        />
        </a>
        <br/ >
          <p>
          <strong>Synergius CRM</strong> to nasze drugie rozwiązanie z&nbsp;zakresu 
          customer relationship, na które możesz przejść w momencie, w którym 
          Quick CRM będzie dla Ciebie niewystarczający. Jego celem jest wsparcie 
          przedsiębiorstw z większymi potrzebami w aspekcie obsługi i budowania 
          relacji z klientami. Program oferuje nie tylko podstawowe funkcje 
          usprawniające proces sprzedaży, ale także dodatkowe elementy 
          przyczyniające się do większej efektywności pracy.
          </p>
          <a href="https://automicob2b.pl/">
          <Img
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 300
          }}
          fluid={data.zdjecie3.childImageSharp.fluid}
          alt="Automico B2B"
        />
        </a>
          <p>
          <strong>Automico B2B</strong> to platforma internetowa do sprzedaży 
          produktów klientom biznesowym. Jest to funkcjonalne narzędzie 
          przeznaczone dla producentów, dystrybutorów i hurtowników, którzy 
          poszukują nowych kanałów sprzedażowych i chcą rozwijać sprzedaż 
          online. Internetowa hurtownia B2B to sposób na sprawną obsługę 
          klientów, a także oszczędność czasu.
          </p>
          
          
        </div>
<div className="text-block">

          <a href="https://serwisplanner.pl/">
          <Img
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 300
          }}
          fluid={data.zdjecie2.childImageSharp.fluid}
          alt="Serwis Planner"
        />
        </a>
          <br />
          <p>
          <strong>Serwis Planner</strong> to program do zarządzania serwisem, którego 
          celem jest wsparcie procesu obsługi różnego rodzaju zleceń. System 
          dedykowany jest przedsiębiorstwom zajmującym się na co dzień realizacją 
          zgłoszeń z zakresu serwisu maszyn i urządzeń, usług instalacyjnych, 
          a także stacjonarnych działań w&nbsp;zakresie napraw sprzętu.
          </p>
          <br/ >
          <br/ >
          <br/ >
          <a href="https://aurabusiness.pl/">
<Img
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 300
          }}
          fluid={data.zdjecie4.childImageSharp.fluid}
          alt="Aura Business"
        />
        </a>
           
          <p>
          Nasze kolejne rozwiązanie to <strong>Aura Business</strong> – dedykowany 
          system do kompleksowego zarządzania procesami biznesowymi. Jego zadaniem 
          jest wsparcie dużych przedsiębiorstw z indywidualnymi potrzebami w wielu 
          różnych obszarach. Specyfika dedykowanego programu pozwala elastycznie 
          dobierać potrzebne moduły, modyfikować istniejące funkcje, a także 
          tworzyć całkowicie nowe rozwiązania.
</p>

         </div>
          </div>
      </Container>

   <Container>
        <div className={styles.benefits}>
          <h3 className={styles.benefits__header}>
            Zainteresowały Cię nasze inne systemy?
          </h3>

          <br />
          <Box textAlign="center">
            <PricingButton mx="auto" to="/kontakt/">
              Skontaktuj się z&nbsp;nami
            </PricingButton>
          </Box>
        </div>
                
</Container>
          <Container>
          <center><h3>Najczęściej zadawane pytania</h3></center>
          <br />
          <div className={styles.questions}>
            <Qa question="Czy podane ceny są cenami netto?">
              Tak, wszystkie zawarte w cenniku kwoty są kwotami netto. Należy do
              nich doliczyć 23% podatek VAT.
            </Qa>
            <Qa question="Czy wersja demo może być moim systemem?">
              Tak. Po uruchomieniu systemu masz dostęp do pełnej funkcjonalności
              QuickCRM w&nbsp;wersji PRO. Już od początku możesz wprowadzać swoje
              dane, a później poprzez płatność PayU wybrać plan i przedłużyć
              ważność systemu na taki okres, jaki potrzebujesz.
            </Qa>
            <Qa question="Co to znaczy, że system jest dostępny w&nbsp;SaaS-ie?">
              QuickCRM dostarczamy w ramach usługi wynajmu. Dzięki temu klient
              bez zbędnych instalacji czegokolwiek bardzo szybko otrzymuje
              gotowy do pracy system. QuickCRM w ramach tej usługi utrzymywany
              jest na naszych serwerach. Aby korzystać z&nbsp;systemu, wystarczy
              dostęp do Internetu i&nbsp;przeglądarka internetowa. System jest
              mobilny, tzn. dostosowany do takich urządzeń jak komputer, tablet
              oraz telefon.
            </Qa>
            <Qa question="Czy mogę wypróbować QuickCRM, zanim zdecyduję się na wynajem?">
              Zdecydowanie. Możesz za darmo, samodzielnie uruchomić swój system
              (<Link to="/demo-crm/">tutaj</Link>). System będzie prezentował
              możliwości pakietu PRO, a subskrypcja będzie ważna 30 dni. Jeśli
              zdecydujesz się na wynajem, możesz dokonać tego samodzielnie z&nbsp;poziomu systemu. W innym przypadku po okresie subskrypcji, system
              zostanie wygaszony.
            </Qa>
            <Qa question="Czy jeśli zdecyduje się na wersję Standard, będę mógł w&nbsp;przyszłości przejść na wyższą wersję?">
              Oczywiście. QuickCRM jest elastyczny. Możesz na początku wybrać
              wersję Start czy Standard, a następnie przejść na wyższy pakiet.
              Przejście na wyższą wersję będzie automatyczne i zajmie kilka
              chwil, zrobisz to z&nbsp;poziomu swojego systemu.
            </Qa>
            <Qa question="Co jeśli mam niestandardowe potrzeby?">
              QuickCRM jest gotowym, ale elastycznym rozwiązaniem. Jeśli nie
              znalazłeś ważnej dla Ciebie możliwości, skontaktuj się z nami,
              spróbujemy dopasować system do Ciebie. Jeśli nie będzie to możliwe
              w dostępnych planach, zaproponujemy Ci nasze inne rozwiązania.
              Jesteśmy dostawcą oprogramowania dla biznesu od ponad 15 lat i w
              swojej ofercie mamy też inne systemy CRM.
            </Qa>
          </div>
          <br />
            <br />
            </Container>
      </Layout>
    );
  };

const allFunctions = [

{
    key: "updates",
    name: "Dostęp do cotygodniowych aktualizacji",
  },
  {
    key: "hosting",
    name: "Utrzymanie na naszych serwerach",
  },
  {
key: "support",
        name: "Pełne wsparcie",
  },
  {
    key: "nolimits",
        name: "Brak limitów zadań, szans sprzedaży oraz klientów",
  },
  {
   key: "tasks",
        name: "Obsługa dowolnego typu zadań i ich delegowanie", 
  },
  { key: "clients",
        name: "Uporządkowane karty kontrahentów i historia kontaktów",
      },
      {
        key: "opps",
        name: "Elastyczny proces zarządzania szansami sprzedaży",
      },
      {key: "opps1",
        name: "Tworzenie wielu kampanii sprzedażowych, własne etapy i cele w kampaniach",
      },
      {
       key: "reports",
        name: "Raporty sprzedażowe", 
      },
      {
        key: "dynamicDesktop",
        name: "Elastyczne zarządzanie pulpitem",
      },
      {
        key: "search",
        name: "Wygodna i funkcjonalna wyszukiwarka firm, osób, zadań",
      },
      {
        key: "import",
        name: "Import osób kontaktowych",
      },
      {
        key: "communicator",
        name: "Wbudowany komunikator z wyszukiwarką",
      },
      {
        key: "permissions",
        name: "Elastyczne zarządzanie uprawnieniami pracowników",
      },
      {key: "gus",
        name: "Automatyczne pobieranie danych kontrahenta z GUS",
      },
      {
        key: "permissions1",
        name: "Eksport osób kontaktowych do pliku xlsx",
      },
  ];

  const pricingData = {
  start: {
    name: "Start",
    price: { month: "9 PLN", year: "5 PLN" },
    button: (
      <MultiStepFormButton
        buttonProps={{
          mb: 4,
          boxShadow: "sm",
          w: "full",
          variantColor: "white",
          background: "white",
          color: "gray.900",
          fontWeight: "bold",
          fontSize: "sm",
        }}
      ></MultiStepFormButton>
    ),
    isRecommended: false,
    special: null,
    bestFeatures: [
      <FeatureList>
        <FeatureListItem>
          Zarządzaj szansami sprzedaży
        </FeatureListItem>
        ,<FeatureListItem>Twórz spójną bazę wiedzy o&nbsp;kontrahentach</FeatureListItem>,
        <FeatureListItem>
          Analizuj wyniki sprzedażowe
        </FeatureListItem>
        ,
        <FeatureListItem>
          Sprawnie deleguj zadaniami
        </FeatureListItem>
        ,<FeatureListItem>Dostosuj pulpit pracy do swoich własnych potrzeb</FeatureListItem>,
      </FeatureList>,
    ],
    features: [
      "updates",
      "hosting",
      "support",
      "nolimits",
      "tasks",
      "clients",
      "opps",
      "opps1",
      "reports",
      "dynamicDesktop",
      "search",
      "import",
    ],
  },
  standard: {
    name: "Standard",
    price: { month: "15 PLN", year: "9 PLN" },
    button: (
      <MultiStepFormButton
        buttonProps={{
          mb: 4,
          boxShadow: "sm",
          w: "full",
          variantColor: "white",
          background: "white",
          color: "gray.900",
          fontWeight: "bold",
          fontSize: "sm",
        }}
      ></MultiStepFormButton>
    ),
    isRecommended: false,
    special: 0,
    bestFeatures: [
      <>
        <Text color="white" mt={0}>
          Funkcje Start oraz:{" "}
        </Text>
        <FeatureList>
          <FeatureListItem>Sprawnie komunikuj się z&nbsp;członkami Twojego zespołu za pomocą wbudowanego komunikatora</FeatureListItem>,
          <FeatureListItem>Zadbaj o&nbsp;bezpieczeństwo danych firmowych poprzez zarządzanie uprawnieniami pracowników</FeatureListItem>,
        </FeatureList>
      </>,
    ],
    features: [
      "updates",
      "hosting",
      "support",
      "nolimits",
      "tasks",
      "clients",
      "opps",
      "opps1",
      "reports",
      "dynamicDesktop",
            "search",
            "import",
            "communicator",
            "permissions",

    ],
  },
  pro: {
    name: "Pro",
    price: { month: "18 PLN", year: "12 PLN" },
    button: (
      <MultiStepFormButton
        buttonProps={{
          mb: 4,
          boxShadow: "sm",
          w: "full",
          variantColor: "white",
          background: "white",
          color: "gray.900",
          fontWeight: "bold",
          fontSize: "sm",
        }}
      ></MultiStepFormButton>
    ),
    isRecommended: false,
    special: 1,
    bestFeatures: [
      <>
        <Text color="white" mt={0}>
          Funkcje Standard oraz:{" "}
        </Text>
        <FeatureList>
          <FeatureListItem>
            Automatycznie uzupełniaj dane kontrahentów dzięki integracji z&nbsp;GUS
          </FeatureListItem>
          ,<FeatureListItem>Pobieraj informacje o&nbsp;osobach kontaktowych do pliku xlsx</FeatureListItem>,
        </FeatureList>
      </>,
    ],
    features: [
      "updates",
      "hosting",
      "support",
      "nolimits",
      "tasks",
      "clients",
      "opps",
      "opps1",
      "reports",
      "dynamicDesktop",
            "search",
            "import",
            "communicator",
            "permissions",
            "gus",
            "permissions1",

    ],
  },
};

export default Cennik;
