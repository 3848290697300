import { Box, Flex, PseudoBox, Text, theme } from "@chakra-ui/core";
import { css } from "@emotion/core";
import React from "react";
import CheckFeature from "../Pricing/CheckFeature";

const FunctionTable = ({ pricingData, allFunctions }) => {
  return (
    <Box mx="auto" maxW="95%" overflowX="auto">
      <PseudoBox
        as="table"
        css={css`
          table-layout: fixed;
          border-collapse: collapse;
          width: 100%;
          min-width: 965px;

          th,
          td {
            padding: 1rem;
          }

          th {
            min-height: 45px;
            background-color: ${theme.colors.gray[100]};
          }

          td,
          th {
            border: 1px solid ${theme.colors.gray[200]};
          }
        `}
      >
        <thead>
          <Box
            as="tr"
            // position="sticky"
            // top="60px"
          >
            <Box as="th" fontSize="lg" w={{ base: "20%", lg: "50%" }}>
              Funkcja
            </Box>
            {Object.keys(pricingData).map((priceLevel) => (
              <th>
                <Flex justifyContent="center">
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    // css={css`
                    //   @media (max-width: 997px) {
                    //     writing-mode: vertical-lr;
                    //     transform: rotate(-45deg);
                    //     transition: all 0.5s;
                    //   }
                    // `}
                  >
                    {pricingData[priceLevel].name}
                  </Text>
                </Flex>
              </th>
            ))}
          </Box>
        </thead>
        <tbody>
          {allFunctions.map((func) => (
            <>
              <tr key={func.key}>
                <td
                  colSpan={
                    func.key === "separator" &&
                    Object.keys(pricingData).length + 1
                  }
                >
                  {func.name}
                </td>

                {func.key !== "separator" &&
                  Object.keys(pricingData).map((priceLevel) => {
                    const isAvailable =
                      pricingData[priceLevel].features &&
                      pricingData[priceLevel].features.includes(func.key)
                        ? true
                        : false;
                    return (
                      <Box as="td" textAlign="center">
                        <CheckFeature isAvailable={isAvailable}></CheckFeature>
                      </Box>
                    );
                  })}
              </tr>
            </>
          ))}
        </tbody>
      </PseudoBox>
    </Box>
  );
};

export default FunctionTable;
