import { Flex } from "@chakra-ui/core";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

const CheckFeature = ({ isAvailable }) => {
  return (
    <Flex
      justifyContent="center"
      align="center"
      fontSize="xl"
      color={isAvailable ? "green.400" : "gray.300"}
    >
      {isAvailable ? <FaCheck></FaCheck> : <FaTimes></FaTimes>}
    </Flex>
  );
};

export default CheckFeature;
