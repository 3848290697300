import { Box, ListIcon, ListItem, Text } from "@chakra-ui/core";
import React from "react";
import { FaCheck } from "react-icons/fa";

const FeatureListItem = ({ children, ...props }) => {
  return (
    <ListItem alignItems="baseline" display="flex" mx={0} {...props}>
      <Box>
        <ListIcon fontSize="sm" icon={FaCheck}></ListIcon>
      </Box>
      <Text as="span" lineHeight="1.5">
        {children}
      </Text>
    </ListItem>
  );
};

export default FeatureListItem;
