import { Button } from "@chakra-ui/core";
import { Link } from "gatsby";
import React from "react";

const PricingButton = (props) => {
  return (
    <Button
      as={Link}
      color="gray.900"
      bg="white"
      borderColor="black"
      mb={4}
      fontSize="sm"
      variant="outline"
      // variantColor="white"
      cursor="pointer"
      {...props}
    ></Button>
  );
};

export default PricingButton;
