import {
  Box,
  Collapse,
  Flex,
  Grid,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/core";
import { css } from "@emotion/core";
import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import FunctionTable from "./FunctionTable";
import PopularBadge from "./PopularBadge";
import Price from "./Price";
import PricingButton from "./PricingButton";

const Pricing = ({
  showFeaturesTables,
  allFunctions,
  pricingData,
  monthDiscount,
  yearDiscount,
  discountColor,
  specialColors = ["hsl(204, 100%, 50%)", "hsl(220, 100%, 50%)"],
  disablePriceChoice,
  disablePriceDisclaimer,
}) => {
  const [payPlan, setPayPlan] = useState(
    disablePriceChoice ? "onetime" : "year"
  );
  const [isTableOpen, setIsTableOpen] = useState(false);

  const handlePayPlanChange = () => {
    setPayPlan((currentPayPlan) =>
      currentPayPlan === "month" ? "year" : "month"
    );
  };

  return (
    <Box>
      {!disablePriceChoice && (
        <Stack
          justify="space-between"
          spacing={4}
          isInline
          align="start"
          mb={16}
        >
          <Box flex={1} textAlign="right">
            <Stack display="inline-flex" spacing={1} justifyContent="center">
              <Text display="block" as="span">
                Rozliczenie roczne
              </Text>
              {yearDiscount && (
                <Text
                  fontSize="sm"
                  color={discountColor}
                  textAlign="center"
                  as="span"
                >
                  {yearDiscount}
                </Text>
              )}
            </Stack>
          </Box>
          <Box>
            <Switch
              css={css`
                & > div {
                  background-color: black !important;
                }
              `}
              isChecked={payPlan === "month"}
              onChange={handlePayPlanChange}
              size="md"
            />
          </Box>
          <Box flex={1} textAlign="left">
            <Stack display="inline-flex" spacing={1} justifyContent="center">
              <Text display="block" as="span">
                Rozliczenie miesięczne
              </Text>
              {monthDiscount && (
                <Text
                  fontSize="sm"
                  color="blue.400"
                  textAlign="center"
                  as="span"
                >
                  {monthDiscount}
                </Text>
              )}
            </Stack>
          </Box>
        </Stack>
      )}

      <Grid
        m={4}
        mb={10}
        gap={8}
        gridTemplateColumns={{
          base: "1fr",
          lg: `repeat(${Object.keys(pricingData).length}, minmax(0, 1fr))`,
        }}
      >
        {Object.keys(pricingData).map((priceLevel) => {
          const { name, bestFeatures, isRecommended, special, button } =
            pricingData[priceLevel];
          const price = pricingData[priceLevel].price[payPlan];

          const props = {
            name,
            bestFeatures,
            price,
            isRecommended,
            special,
            button,
          };
          return (
            <Box maxW="full">
              <OptionBox
                customPrice={price?.type === "custom"}
                payPlan={payPlan}
                bg={special !== null && specialColors && specialColors[special]}
                {...props}
              ></OptionBox>
            </Box>
          );
        })}
      </Grid>
      {!disablePriceDisclaimer && (
        <Text color="black" textAlign="center" mb={10}>
          Wszystkie podane ceny są cenami netto za użytkownika.
        </Text>
      )}
      {showFeaturesTables && (
        <>
          <Flex w="full" justifyContent="center">
            <PricingButton
              as="button"
              onClick={() => setIsTableOpen((isOpen) => !isOpen)}
              rightIcon={isTableOpen ? FaChevronUp : FaChevronDown}
            >
              {isTableOpen
                ? "Zwiń porównanie pakietów"
                : "Zobacz porównanie pakietów"}
            </PricingButton>
          </Flex>
          <Collapse isOpen={isTableOpen}>
            <FunctionTable
              pricingData={pricingData}
              allFunctions={allFunctions}
            ></FunctionTable>
          </Collapse>
        </>
      )}
    </Box>
  );
};

const OptionBox = ({
  name,
  price,
  bestFeatures,
  isRecommended,
  bg,
  button,
  payPlan,
  customPrice,
}) => {
  return (
    <Box
      boxShadow="m1"
      p={8}
      rounded="lg"
      bg={bg || "white"}
      color={bg ? "white" : "gray.900"}
    >
      <Stack isInline justify="space-between" align="center" mb={4}>
        <Text fontSize="lg" color={bg ? "white" : "gray.900"} m={0}>
          {name}
        </Text>
        {isRecommended && (
          <PopularBadge
            color={bg ? "white" : "gray.900"}
            bg={bg}
          ></PopularBadge>
        )}
      </Stack>
      <Price
        customPrice={customPrice}
        payPlan={payPlan}
        color={bg ? "white" : "gray.900"}
        mb={4}
        price={price}
      ></Price>
      {button}
      {/* <Button
        as={Link}
        to={button.link}
        color="gray.900"
        bg="white"
        boxShadow="sm"
        borderWidth={0}
        mb={4}
        fontSize="sm"
        w="full"
        variant="ghost"
        // variantColor="white"
        cursor="pointer"
      >
        {button.text}
      </Button> */}
      <Box>{bestFeatures}</Box>
    </Box>
  );
};

export default Pricing;
